import '../../styles/pages/fall-winter-2020/a-virus-hits-home.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2020-stories/more-stories"
import StoryNav from "../../components/fall-winter-2020-stories/story-nav"


import socialBanner from "../../images/fall-winter-2020/social-banners/a-virus-hits-home.jpg";

import TitleGraphic from "../../images/fall-winter-2020/a-virus-hits-home/title-graphic.png";
import roxanneAguirreGrandma from "../../images/fall-winter-2020/a-virus-hits-home/Roxanne-Aguirre-Grandma.jpg";
import barryBarnhill from "../../images/fall-winter-2020/a-virus-hits-home/barry-barnhill.jpg";
import christineVu from "../../images/fall-winter-2020/a-virus-hits-home/christine-vu.jpg";
import erinGreenberg from "../../images/fall-winter-2020/a-virus-hits-home/erin-greenberg.jpg";
import jenniferWichman from "../../images/fall-winter-2020/a-virus-hits-home/jennifer-wichman.jpg";
import kateBayhan from "../../images/fall-winter-2020/a-virus-hits-home/kate-bayhan.jpg";
import nicholasBedolla from "../../images/fall-winter-2020/a-virus-hits-home/nicholas-bedolla.jpg";
import sonyaSandhu from "../../images/fall-winter-2020/a-virus-hits-home/sonya-sandhu.jpg";
import susanMorales from "../../images/fall-winter-2020/a-virus-hits-home/susan-morales.jpg";
import zenaidaCo from "../../images/fall-winter-2020/a-virus-hits-home/zenaida-co.jpg";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'A Virus Hits Home';
    var pageDesc = 'After being personally affected by the COVID-19 pandemic, student Roxanne Aguirre is more determined than ever to become a nurse.';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="a-virus-hits-home" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <section className="feature-hero animated fadeIn slow">
          <div className="hero-text">
            <h1 className="first-word animated fadeInDown delay-1s"><img src={TitleGraphic} alt={pageTitle} /></h1>
            <p className="sub-heading animated fadeInUp delay-2s">She recovered from COVID-19, but her grandmother didn’t. Becoming a nurse now carries new meaning.</p>
            <p className="author animated fadeInUp delay-2s">By Lynn Juliano / Photos by Matt Gush</p>
          </div>
        </section>
        <article className="wrap small">

          <ScrollAnimation animateIn="fadeIn">
          <blockquote className="align-right align-outside">
            <p>“I try to treat my patients how I hope my grandmother was treated in her dying hours.”</p>
            <cite>- Roxanne Aguirre, nursing student</cite>
          </blockquote>

          <p><span className="intro-text">When Roxanne Aguirre began coughing,</span> she knew she had contracted the virus. She had mild symptoms: a cough, chest pain and a sore throat at first. As the days progressed, she lost her sense of taste and smell. She monitored her vitals daily, but never spiked a fever or experienced desaturation in oxygen levels.</p>

          <p>Her fears were confirmed: She tested positive for COVID-19, the novel coronavirus that has amassed to more than 60 million cases and 1 million deaths worldwide since its outbreak in December 2019.</p>

          <p>As a certified nursing assistant working in every area of the hospital from the emergency department and intensive care unit to the medical-surgical and COVID-19 units, Aguirre knew she was at increased risk for contracting the virus. She took every precaution she could to protect herself and her family, including donning personal protective equipment and socially distancing from those outside her immediate family.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>“Because I was working with COVID-19 patients, I mentally prepared myself for the possibility of contracting the virus. But actually contracting the virus was surreal,” she admits.</p>

          <p>After testing positive, Aguirre isolated for two weeks. Her husband, Robert, a civil engineer, also stayed home from work. He took over the household responsibilities, including cooking and getting their three daughters — Ruby, 10; Reyna, 8; and Rosy, 6 — ready for bed each night.</p>

          <p>Though she worried constantly, Aguirre’s husband and daughters never got sick.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <h2>Losing a Loved One</h2>

          <p>Looking back on her childhood, Aguirre remembers being raised by her grandmother, Julia Ortiz, while her mother worked night shifts and put herself through nursing school.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={roxanneAguirreGrandma} alt="Roxanne Aguirre" />
            <figcaption>Cal State Fullerton student Roxanne Aguirre (left) is more determined than ever to become a nurse, after losing her grandmother (right), Julia Ortiz, to COVID-19.</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>“It was a pleasure being raised by my grandmother,” says Aguirre, admiring how the former seamstress worked 16- to 18- hour days in the Los Angeles Garment District to support her family. “My grandmother was an amazing cook, and my mother and I still use her recipes to this day.”</p>

          <p>Ortiz began to suffer from dementia in the last seven to 10 years. While living in a nursing home, she contracted COVID-19. But, unlike her granddaughter, Ortiz never recovered and died at the age of 92.</p>


          <p>“I was unable to see or talk to my grandmother before she died,” says Aguirre.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>“Knowing my grandmother died with only her nurse by her side, and that many other people have experienced the same type of death, makes me feel that nurses are of the utmost importance at this time,” reflects Aguirre. “As nurses, and even certified nursing assistants, it is important for us to empathize and have compassion for patients who can’t have family with them.</p>

          <p>“During those 12 hours that I care for them, I am their family. These patients are scared and oftentimes confused. I hold their hands, talk to them and try to make them feel as comfortable as possible.</p>

          <p>“I try to treat my patients how I hope my grandmother was treated in her dying hours.”</p>

          <p>For those who shirk or downplay the pandemic’s effects, Aguirre is gentle: “It’s difficult for people to understand the severity of the pandemic until it personally affects you. Once you go through something like losing a loved one from this virus, or being physically sick yourself, it becomes real.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <h2>Training Nurses for the Future</h2>

          <p>“It takes incredible bravery to choose to work with COVID-19 patients, to choose to risk your health and possibly your life to help another,” says Kate Bayhan, lecturer in nursing and pre-licensure coordinator. “Roxanne is an example of the resilience of a Titan: She faces her fears daily and presses toward her goals.</p>

          <p>“More so, Roxanne is living in uncertainty — something we are all doing right now,” adds Bayhan, who works as an emergency nurse along with her teaching responsibilities. “It takes a toll on you to continue to push forward, especially when you have no idea what lies ahead.”</p>
          
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">

          <blockquote className="align-left align-outside">
            <p>“It takes incredible bravery to choose to work with COVID-19 patients, to choose to risk your health and possibly your life to help another.”</p>
            <cite>- Kate Bayhan, CSUF lecturer in nursing and pre-licensure coordinator</cite>
          </blockquote>

          <p>At the beginning of the pandemic, all Cal State Fullerton nursing classes transitioned to virtual instruction and clinical rotations were paused in an effort to preserve resources for frontline workers and to ensure student safety. The School of Nursing’s clinical placement team diligently worked to secure placements for the fall semester with such conditions as smaller groups, PPE provisions, strict screening and reporting. For additional hands-on training, some students have returned to the university’s Nursing Simulation Lab with increased safety precautions.</p>

          <p>“Nationwide, nursing schools are struggling with securing clinical placements,” explains Bayhan. “We still have a lot of clinical sites that are closed to us, but we have managed to secure some so our nursing students can continue their education.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">

          <p>Crediting CSUF’s top-ranked School of Nursing with launching her health care career, Aguirre completed her clinical rotations before the COVID-19 outbreak and was offered a certified nursing assistant position at the same community hospital.</p>

          <p>As a student, Aguirre has had opportunities to serve as the student representative of her nursing cohort and as a member of the National Student Nurses Association.</p>

          <p>“CSUF helps us, as students, build relationships with hospital staff and administrators, which makes us stand out when pursuing our careers,” she explains. “The university offers us great clinical sites and amazing instructors.”</p>
          
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">

          <p>In fact, nine Cal State Fullerton faculty members have been serving on the front line during the pandemic, working in emergency departments and intensive care units while continuing to teach.</p>

          <p>“It’s wonderful that our faculty work in the field they teach — their experience comes from firsthand knowledge,” says Aguirre.</p>

          <p>During the global health crisis, nursing faculty members have been emphasizing the latest self-care practices, introducing students to the emerging field of telehealth, and engaging them in innovative COVID-19 research.</p>
          
          
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">

          <p>Aguirre anticipates completing her Bachelor of Science in nursing by the end of the year. After graduation, she plans to take the National Council Licensure Examination to become a registered nurse, and hopes to one day pursue a master’s degree and become a clinical nursing instructor.</p>

          <p>“Anyone who knows me, knows that my biggest inspiration to pursue a nursing career has been my mother. Her hard work, compassion, and dedication to her family and career are what inspire me,” says Aguirre.</p>

          <p>“The pandemic only solidifies my decision to become a nurse. Caring for those in need, no matter what the disease, is my honor.” <span className="end-mark" /></p>
          </ScrollAnimation>

        </article>

        <div class="nursing">
          <div class="wrap large">
            <div class="row">
              <div class="col-6">

                <h2>Faculty on the Front Lines</h2>
                <p>Nine nursing faculty members — many Cal State Fullerton alumni (degrees listed below) — have been working in emergency departments and intensive care units during the pandemic, including:</p>

                <ul className="nursing-list">
                  <li>
                    <img src={barryBarnhill} alt="" />
                    <span>Barry Barnhill, lecturer in nursing</span>
                  </li>
                  <li>
                    <img src={kateBayhan} alt="" />
                    <span>Kate Bayhan ’05, ’10, ’19 (B.A. English, B.S. and M.S. nursing, DNP), lecturer in nursing</span>
                  </li>
                  <li>
                    <img src={nicholasBedolla} alt="" />
                    <span>Nick Bedolla, lecturer in nursing</span>
                  </li>
                  <li>
                    <img src={zenaidaCo} alt="" />
                    <span>Zenaida Co ’14, ’18 (M.S. nursing-nursing leadership, DNP), lecturer in nursing</span>
                  </li>
                  <li>
                    <img src={erinGreenberg} alt="" />
                    <span>Erin Greenberg ’09, ’13 (M.A. speech communication, M.S. nursing), lecturer in nursing</span>
                  </li>
                  <li>
                    <img src={susanMorales} alt="" />
                    <span>Susan Morales, lecturer in nursing</span>
                  </li>
                  <li>
                    <img src={sonyaSandhu} alt="" />
                    <span>Sonya Sandhu ’12 (B.S. nursing, M.S. nursing-nursing leadership), lecturer in nursing</span>
                  </li>
                  <li>
                    <img src={christineVu} alt="" />
                    <span>Christine Vu ’12 (M.S. nursing), assistant professor of nursing</span>
                  </li>
                  <li>
                    <img src={jenniferWichman} alt="" />
                    <span>Jennifer Wichman ’15 (M.S. nursing-nurse educator), lecturer in nursing</span>
                  </li>
                </ul>

              </div>
              <div class="col-6">

                <h2>Top-Ranked School of Nursing</h2>
                <p>U.S. News & World Report’s 2020 Best Graduate Schools ranks Cal State Fullerton’s School of Nursing among the top in the nation:</p>

                <ul>
                  <li>Nurse anesthesia doctoral program, offered in partnership with the Kaiser Permanente School of Anesthesia, ranks No. 4.</li>
                  <li>In the nursing-midwifery category, the Master of Science in nursing-women’s health care program ranks No. 21.</li>
                </ul>

                <h2>Number of Students Enrolled in Nursing Programs</h2>
                <ul>
                  <li>828 Bachelor of Science in nursing</li>
                  <li>75 Master of Science in nursing</li>
                  <li>136 Doctor of Nursing Practice</li>
                  <li>80 school nurse services credential</li>
                </ul>

                <p><small>(as of November 2020)</small></p>

              </div>
            </div>
          </div>
        </div>


        <article className="wrap small">

          <ScrollAnimation animateIn="fadeIn">
          <p className="learn-more">Support the College of Health and Human Development<br /><a href="https://campaign.fullerton.edu/hhd" target="_blank">campaign.fullerton.edu/hhd</a></p>
          </ScrollAnimation>

        </article>
        <MoreStories slug="a-virus-hits-home" />
        <StoryNav slug="a-virus-hits-home" />
      </Layout>
    );
  }
} 